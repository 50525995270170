import React, { useState, useEffect } from 'react';
import {Grid, Select, SelectChangeEvent } from '@mui/material';
import { useNotify, useDataProvider, useTranslate, usePermissions } from 'react-admin';
import { MenuItem, Box, OutlinedInput, FormControl, InputLabel } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import CustomChip from './components/react-admin/CustomChip';

interface TagsManagerProps {
  record?: Record<string, any>;
  isEditable?: boolean;
  label?: string;
}

const basicTags = {
  ':thumbs-up:': 'Thumbs up',
  ':thumbs-down:': 'Thumbs down',
};

const adminTags = {
  'Needs review': 'Needs review',
  'Reviewed': 'Reviewed',
  'Finetuning': 'Finetuning',
  'Example': 'Example'

};

const emojiTags = {
  ':thumbs-up:': 'Thumbs up',
  ':thumbs-down:': 'Thumbs down',
  ':needs-review:': 'Needs review',
  ':reviewed:': 'Reviewed',
  ':finetuning:': 'Finetuning',
  ':example:': 'Example',
};

const allTags = Object.assign({}, basicTags, adminTags, emojiTags);
const allTagsWithEmoji = Object.assign({}, basicTags, adminTags, emojiTags);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function getStyles(tag: string, selectedTags: readonly string[], theme: Theme) {
  return {
    fontWeight: selectedTags.includes(tag)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const TagsManager: React.FC<TagsManagerProps> = ({ record, label, isEditable = true }) => {
  const theme = useTheme();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [tags, setTags] = useState<string[]>([]);

  const isAdmin = ['admin', 'partner'].includes(permissions);
  const tagList = isAdmin ? { ...basicTags, ...adminTags } : basicTags;
  const availableTags = Object.keys(tagList);

  useEffect(() => {
    if (record && Array.isArray(record.tags)) {
      const filteredTags = isAdmin 
        ? record.tags 
        : record.tags.filter((tag: string) => tag in basicTags);
      setTags(filteredTags);
    } else {
      setTags([]);
    }
  }, [record, isAdmin]);

  const saveTags = (updatedTags: string[]) => {
    if (record) {
      dataProvider.update('conversations', { 
        id: record.id, 
        data: { tags: updatedTags }, 
        previousData: record 
      })
        .then(() => {
          notify(`resources.conversations.tag_updated`, { type: 'success' });
        })
        .catch((error) => {
          notify(`Error updating tags: ${error.message}`, { type: 'error' });
        });
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof tags>) => {
    const {
      target: { value },
    } = event;
    const updatedTags = typeof value === 'string' ? value.split(',') : value;
    setTags(updatedTags);
    saveTags(updatedTags);
  };

  const handleDelete = (tagToDelete: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedTags = tags.filter(tag => tag !== tagToDelete);
    setTags(updatedTags);
    saveTags(updatedTags);
  };

  const handleRatingChange = (rating: number) => {
    // Remove any existing rating tags
    const tagsWithoutRating = tags.filter(tag => !tag.startsWith('rating-'));
    // Add the new rating tag
    const updatedTags = [...tagsWithoutRating, `rating-${rating}`];
    setTags(updatedTags);
    saveTags(updatedTags);
  };

  const renderTagLabel = (tag: string) => {
    return tagList[tag as keyof typeof tagList] || tag;
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <FormControl sx={{ width: '100%' }}>
          {label && (
            <InputLabel id="tags-multiple-chip-label">{label}</InputLabel>
          )}
          <Select
            labelId="tags-multiple-chip-label"
            id="tags-multiple-chip"
            multiple
            value={tags}
            onChange={handleChange}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} onClick={(e) => e.stopPropagation()}>
                {Array.isArray(selected) && selected.map((value) => (
                  <CustomChip 
                    key={value} 
                    label={renderTagLabel(value)}
                    size="small"
                    onDelete={isEditable ? handleDelete(value) : undefined}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
            disabled={!isEditable}
            sx={{
              '&.MuiInputBase-root': {
                height: 'auto !important',
                minHeight: '35px'
              },
              '& .MuiInputBase-input': {
                height: 'auto !important',
                minHeight: '35px',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '8px 14px !important'
              }
            }}
          >
            {availableTags.map((tag) => (
              <MenuItem
                key={tag}
                value={tag}
                style={getStyles(tag, tags, theme)}
              >
                {renderTagLabel(tag)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TagsManager;
export { allTags, allTagsWithEmoji };